<template>
    <el-row>
        <el-col :xl="12" :lg="16">
            <h2>{{form.MenuName || "新增菜单"}}</h2>
            <el-form :model="form" :rules="rules" ref="dialogForm" label-width="130px" label-position="left">
                <el-form-item label="显示名称" prop="MenuName" :rules="[{required: true, message: '请输入显示名称', trigger: 'blur'},{max:20,min:2,message: '长度在 2 到 20 个字符', trigger: 'blur'}]">
                    <el-input v-model="form.MenuName" clearable placeholder="菜单显示名字"></el-input>
                </el-form-item>
                <el-form-item label="上级菜单" prop="MenuPID" :rules="[]">
                    <el-cascader v-model="form.MenuPID" :options="menu" :props="menuProps" :show-all-levels="false" clearable></el-cascader>
                    <!-- <div class="el-form-item-msg">这里还有点问题！el-cascader的props的label 现暂只支持字符串还未支持自定义函数 </div> -->
                </el-form-item>
                <el-form-item label="类型" prop="MenuType">
                    <el-radio-group v-model="form.MenuType">
                        <el-radio-button label="0">目录</el-radio-button>
                        <el-radio-button label="1">菜单</el-radio-button>
                        <el-radio-button label="2">按钮</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="打开方式" prop="OpenType">
                    <el-radio-group v-model="form.OpenType">
                        <el-radio-button label="0">无</el-radio-button>
                        <el-radio-button label="1">组件</el-radio-button>
                        <el-radio-button label="2">内链</el-radio-button>
                        <el-radio-button label="3">外链</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="PC路径" prop="MenuPCUrl" :rules="[{required: true, message: '请输入PC菜单路径', trigger: 'blur'},{max:200,min:1,message: '长度在 1 到 200 个字符', trigger: 'blur'}]">
                    <el-input v-model="form.MenuPCUrl" clearable placeholder="菜单路径"></el-input>
                </el-form-item>
                <el-form-item label="PC菜单图标" prop="MenuPCIcon">
                    <e-icon-picker v-model="form.MenuPCIcon" />
                    <!-- <el-input v-model="form.MenuPCIcon" clearable placeholder=""></el-input> -->
                </el-form-item>
                <el-form-item label="Mobile路径" prop="MenuMobileUrl" :rules="[{required: true, message: '请输入Mobile菜单路径', trigger: 'blur'},{max:200,min:1,message: '长度在 1 到 200 个字符', trigger: 'blur'}]">
                    <el-input v-model="form.MenuMobileUrl" clearable placeholder="菜单路径"></el-input>
                </el-form-item>
                <el-form-item label="Mobile菜单图标" prop="MenuMobileIcon">
                    <el-upload class="avatar-uploader" :action="`${this.$CONFIG.API_URL}/File/UploadFileAvatar`" :show-file-list="false" :on-success="function (res, file,fileList) { return handleAvatarSuccess(res, file,fileList)} " :before-upload="beforeAvatarUpload" :headers="headers">
                        <img v-if="form.MenuMobileIcon" :src="form.MenuMobileIcon" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <!-- <e-icon-picker v-model="form.MenuMobileIcon" /> -->
                    <!-- <el-input v-model="form.MenuPCIcon" clearable placeholder=""></el-input> -->
                </el-form-item>
                <el-form-item label="重定向" prop="Redirect">
                    <el-input v-model="form.Redirect" clearable placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="组件" prop="Component">
                    <el-autocomplete v-model="form.Component" :fetch-suggestions="querySearch" :debounce="10" clearable placeholder=""></el-autocomplete>
                    <div class="el-form-item-msg">如父节点、链接或Iframe等没有组件的菜单不需要填写</div>
                </el-form-item>
                <el-form-item label="别名" prop="MenuAnotherName">
                    <el-autocomplete v-model="form.MenuAnotherName" :fetch-suggestions="querySearch" :debounce="10" clearable placeholder=""></el-autocomplete>
                </el-form-item>
                <el-form-item label="排序" prop="Sort">
                    <el-input-number v-model="form.Sort" :min="1" :max="10000" label="序号"></el-input-number>
                    <div class="el-form-item-msg">数字越小，排序越靠前</div>
                </el-form-item>
                <el-form-item label="备注" prop="RemarkInfo">
                    <el-input type="textarea" maxlength="200" v-model="form.RemarkInfo" label="备注"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submit" :loading="isSubmit">保 存</el-button>
                </el-form-item>
            </el-form>

        </el-col>
    </el-row>
</template>

<script>
export default {
    props: {
        menu: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            form: {
                MenuPID: -1,
                MenuName: "",
                MenuType: 0,
                MenuPCIcon: "",
                MenuMobileIcon: "",
                MenuPCUrl: "",
                MenuMobileUrl: "",
                OpenType: 0,
                Redirect: "",
                Component: "",
                Sort: 100,
                Id: 0,
                RemarkInfo: "",
            },
            menuProps: {
                value: "Id",
                label: "MenuName",
                children: "Children",
                checkStrictly: true,
            },
            headers:{},
            rules: {},
            views: [],
            isSubmit: false,
        };
    },
    mounted() {
        this.views = this.getViews();
        // 仅在整个视图都被渲染之后才会运行的代码
        let adminInfo = this.$TOOL.data.get("adminInfo");
        this.headers = {
            Authorization: "Bearer " + adminInfo.JwtToken,
        };
    },
    methods: {
        //表单注入数据
        setData(data, pid) {
            this.form = data;
            this.form.MenuPID = pid;
            //可以和上面一样单个注入，也可以像下面一样直接合并进去
            //Object.assign(this.form, data)
        },
        //获取所有视图组件
        getViews() {
            const filesUrl = [];
            //不知道为什么 require.context 会引起Webpack会一并把结果都打包进来使得此文件过大
            // let files = require.context('@/views', true, /\.vue$/)
            // files.keys().forEach(file => {
            // 	// 如需删除index? .replace(/\/index$/, "")
            // 	filesUrl.push({
            // 		value: file.replace(/^\.\/(.*)\.\w+$/, '$1')
            // 	})
            // })
            return filesUrl;
        },
        querySearch(queryString, cb) {
            var results = this.getViews();
            results = results.filter(
                (item) => item.value.indexOf(queryString) !== -1
            );
            cb(results);
        },
        submit() {
            this.isSubmit = true;

            this.$refs["dialogForm"].validate((valid) => {
                if (valid) {
                    if (this.form.MenuPID == undefined) this.form.MenuPID = -1;
                    else if (this.form.MenuPID.length > 0)
                        this.form.MenuPID =
                            this.form.MenuPID[this.form.MenuPID.length - 1];
                    else if (this.form.MenuPID == 0) this.form.MenuPID = -1;
                    this.$API.Menu.AddOrUpdate.post(this.form).then((res) => {
                        if (res.Code == 204) {
                            this.$notify({
                                title: "成功",
                                message: "操作成功！",
                                type: "success",
                            });
                        }
                        // else {
                        //     this.$notify({
                        //         title: "失败",
                        //         message: res.Message,
                        //         type: "error",
                        //     });
                        // }
                        this.isSubmit = false;
                    });
                } else {
                    this.isSubmit = false;
                    console.log("验证失败");
                }
            });
        },
        //图片上传成功
        handleAvatarSuccess(res, file, fileList, obj) {
            console.log(res);
            this.form.MenuMobileIcon = URL.createObjectURL(file.raw);
            //this.imageUrl = URL.createObjectURL(file.raw);
            this.form.MenuMobileIcon = res.Data.Url;
            //this.$emit("handleAvatarSuccess", res);
        },
        //图片上传判断
        beforeAvatarUpload(file) {
            const fileType = file.type;
            const isImg = !(
                fileType != ".jpg" &&
                fileType != ".JPG" &&
                fileType != "image/jpeg" &&
                fileType != ".png" &&
                fileType != ".PNG" &&
                fileType != "image/png" &&
                fileType != ".gif" &&
                fileType != ".GIF" &&
                fileType != "image/gif"
            );
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isImg) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isImg && isLt2M;
        },
    },
};
</script>

<style scoped>
h2 {
    font-size: 17px;
    color: #3c4a54;
    padding: 0 0 30px 0;
}
.el-input-group__append,
.el-input-group__prepend {
    display: table-cell;
}
</style>
