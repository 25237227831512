<template>
    <el-container>
        <el-aside width="300px">
            <el-container>
                <el-header>
                    <el-input placeholder="输入关键字进行过滤" v-model="menuFilterText" clearable></el-input>
                </el-header>
                <el-main class="nopadding">
                    <el-tree ref="menu" class="menu" node-key="Id" :data="menuList" :props="menuProps" draggable highlight-current :expand-on-click-node="false" check-strictly show-checkbox :filter-node-method="menuFilterNode" @node-click="menuClick">

                        <template #default="{node, data}">
                            <span class="custom-tree-node">
                                <span class="label"><i :class="data.MenuPCIcon" style="padding-right:5px"></i> {{ node.label }}</span>
                                <span class="do">
                                    <i class="el-icon-plus" @click.stop="add(node, data)"></i>
                                </span>
                            </span>
                        </template>

                    </el-tree>
                </el-main>
                <el-footer style="height:51px;">
                    <el-button type="primary" size="mini" icon="el-icon-plus" @click="add()"></el-button>
                    <el-button type="danger" size="mini" plain icon="el-icon-delete" @click="delMenu"></el-button>
                </el-footer>
            </el-container>
        </el-aside>
        <el-container>
            <el-main class="nopadding" style="padding:20px;">
                <save ref="save" :menu="saveMenuList"></save>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
let newMenuIndex = 1;
import save from "./save";

export default {
    name: "menu",
    components: {
        save,
    },
    data() {
        return {
            menuList: [],
            saveMenuList: [],
            menuProps: {
                label: (data) => {
                    return data.MenuName;
                },
                children: (data) => {
                    return data.Children;
                },
            },
            menuFilterText: "",
        };
    },
    watch: {
        menuFilterText(val) {
            this.$refs.menu.filter(val);
        },
    },
    mounted() {
        this.getMenu();
    },
    methods: {
        //加载树数据
        getMenu() {
            this.$API.Menu.GetMenuList.get().then((res) => {
                this.menuList = res;
                this.saveMenuList = this.menuListGet(res);
            });
        },
        //树点击
        menuClick(data, node) {
            var pid = node.MenuPID == -1 ? -1 : node.parent.data.Id;
            this.$refs.save.setData(data, pid);
        },
        //树过滤
        menuFilterNode(value, data) {
            if (!value) return true;
            var targetText = data.MenuName;
            return targetText.indexOf(value) !== -1;
        },
        //增加
        add(node) {
            var newMenuName = "未命名" + newMenuIndex++;
            var newMenuData = {
                MenuPID: -1,
                MenuName: newMenuName,
                MenuType: 0,
                MenuPCIcon: "",
                MenuMobileIcon: "",
                MenuPCUrl: "",
                MenuMobileUrl: "",
                OpenType: 0,
                Redirect: "",
                Component: "",
                Sort: 100,
                RemarkInfo: "",
                Id: newMenuIndex * -1,
            };
            if (node) {
                this.$refs.menu.append(newMenuData, node);
                var lastNode = node.childNodes[node.childNodes.length - 1];
                this.$refs.menu.setCurrentKey(lastNode.data.name);
                var pid = node.data.name;
                this.$refs.save.setData(newMenuData, pid);
            } else {
                this.$refs.menu.append(newMenuData);
                var newNode = this.menuList[this.menuList.length - 1];
                this.$refs.menu.setCurrentKey(newNode.name);
                this.$refs.save.setData(newMenuData);
            }
        },
        //删除菜单
        delMenu() {
            var that = this;
            var CheckedNodes = that.$refs.menu.getCheckedNodes();
            if (CheckedNodes.length == 0) {
                that.$message.warning("请选择需要删除的项");
                return false;
            }
            var ids = [];
            CheckedNodes.forEach((item) => {
                console.log(item.Id);
                if (item.Id <= 0) that.$refs.menu.remove(item);
                else ids.push(item.Id);
            });
            if (ids.length > 0)
                that.$API.Menu.FakeDelete.delete(ids).then((res) => {
                    if (res.Code == 204) {
                        console.log("old", that.$refs.menu);
                        CheckedNodes.forEach((item) => {
                            that.$refs.menu.remove(item);
                        });
                        console.log("new", that.$refs.menu);
                        that.$notify({
                            title: "成功",
                            message: res.Message,
                            type: "success",
                        });
                    } else
                        that.$notify({
                            title: "失败",
                            message: res.Message,
                            type: "error",
                        });
                });
        },
        /**
         * 格式化菜单数据
         */
        menuListGet(menuList) {
            for (let index = 0; index < menuList.length; index++) {
                //const element = menuList[index];
                if (menuList[index].Children.length > 0)
                    menuList[index].Children = this.menuListGet(
                        menuList[index].Children
                    );
                else {
                    menuList[index].Children = null;
                }
            }
            return menuList;
        },
    },
};
</script>

<style scoped>
.custom-tree-node {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 24px;
    height: 100%;
}
.custom-tree-node .label {
    display: flex;
    align-items: center;
    height: 100%;
}
.custom-tree-node .label .el-tag {
    margin-left: 5px;
}
.custom-tree-node .do {
    display: none;
}
.custom-tree-node .do i {
    margin-left: 5px;
    color: #999;
    padding: 5px;
}
.custom-tree-node .do i:hover {
    color: #333;
}

.custom-tree-node:hover .do {
    display: inline-block;
}
.icon {
    padding-right: 10px;
}
</style>
